.nazdaa__aboutus{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background: radial-gradient(147.58% 111.16% at 21.04% 16.09%, rgba(168, 196, 73, 0.45) 35.54%, rgba(241, 142, 36, 0.06) 100%);

}

.nazdaa__aboutus-heading{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin: 0;
    margin-right: 5rem;
}

.nazdaa__aboutus-heading-content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.nazdaa__aboutus-heading-content img{
    width: 23rem;
    margin-left: 3rem;
    margin-top: 2rem
}
.nazdaa__aboutus-heading h1{
    font-family: var(--heading-font);
    color: var(--color-header);
    margin-bottom: 3rem;
}

.nazdaa__aboutus-heading-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 30px;
    line-height: 45px;
}

.imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nazdaa__aboutus-heading-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    margin: 0;
    margin-top: 2rem;
    color: #FF8A71;
}

.nazdaa__aboutus-container{
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 920px) {
    .nazdaa__aboutus{
        flex-direction: column;
    }

    .nazdaa__aboutus-heading{
        margin: 0 0 2rem 0;
    }
    .nazdaa__aboutus-heading-content .imageContainer img{
        width: 16rem;
    }
    .nazdaa__aboutus-heading{
        margin-left: 1rem;
    }
    .nazdaa__aboutus-container{
        padding: .8rem;
        width: 100%;
        gap: 1rem;
    }
    .nazdaa__aboutus-container h3{
        font-size: 22px;
    }
    
} 
