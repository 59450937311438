.nazdaa__contact-heading__header h1{
    font-family: var(--heading-font);
    color: var(--color-header);
    margin-bottom: 2rem;
}
.nazdaa__contact-heading__content h3{
    font-family: var(--font-family);
    width: 40vw;
}
.nazdaa__contact-body__content{
    display: flex;
    flex-direction: column;
    padding: 0;
    width: max-content;
    margin: auto;
}
.nazdaa__contact-body__img1 {
    display: flex;
  }
.nazdaa__contact-body__img1 img{
    width: 24rem;
}
.nazdaa__contact-body__img2 {
    display: flex;
  }
.nazdaa__contact-body__img2 img{
    width: 20rem;
    justify-content: flex-end;
    align-items: flex-end;
}
.nazdaa__contact-body{
    display: flex;
    flex-direction: row;
    padding: 0;
}
.nazdaa__contact-body__content h1{
    font-family: var(--heading-font);
    width: 26vw;
    margin-bottom: 3rem;
}
.nazdaa__contact-body__content button{
    background: #9db153;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100px;
    border-radius: 5px;
    height: 50px;
    transition: background 0.3s ease;
    font-family: var(--heading-font);
    font-weight: 400;
    font-size: 16px;
    margin: auto;
}
.nazdaa__contact-body__content button:hover{
    background: #707F3C;
    cursor: pointer;

}

@media screen and (max-width: 920px) {
    .nazdaa__contact-heading__header{
        padding-left: 1rem;
    }
    .nazdaa__contact-heading__content{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
    .nazdaa__contact-heading__content h3{
        width: 80%;
    }
    .nazdaa__contact-body {
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    .nazdaa__contact-body img{
        width: 150px;
        height: 120px;
    }
    .nazdaa__contact-body{
        display: flex;
        flex-direction: column;
    }
    .nazdaa__contact-body__content h1{

        width: 300px;
    }
}