.nazdaa__projectsPage-heading {
  height: 24vw;
  background-image: url(../../../assets/projectsPage/projPageheading.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nazdaa__projectsPage-heading h1 {
  font-family: var(--heading-font);
  word-spacing: 1rem;
  letter-spacing: 0.3rem;
  color: #ffffff;
  font-size: 46px;
}

.nazdaa__projectsPage-anim_fig {
  overflow: hidden;
}

.nazdaa__projectsPage-heading h3 {
  font-family: var(--font-family);
  color: #ffffff;
  word-spacing: 0.5rem;
  letter-spacing: 0.1rem;
  margin-top: 0.5rem;
}

.nazdaa__projectsPage-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nazdaa__projectsPage-content__projectsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95vw;
}
.nazdaa__projectsPage-content__projectsCard img {
  width: 35rem;
  height: 20rem;
}
.nazdaa__projectsPage-content__projectsCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 17rem;
  width: 35rem;
  margin-right: 2.5rem;
  margin-left: 2.5;
  margin-bottom: 3rem;
  box-shadow: none;
}

.nazdaa__projectsPage-content__projectsCard h3{
  margin-bottom: 1rem;
}


.nazdaa__projectsPage-content__projectsCard:hover {
  transition: box-shadow 0.3s ease;
}
.nazdaa__projectsPage-content__projectsContainer .nazdaa__projectsCard {
  background: none;
  border: none;
  padding-top: 2rem;
}

.nazdaa__projectsPage-content__h1 {
  font-family: var(--heading-font);
  color: var(--color-header);
  letter-spacing: 0.1rem;
  word-spacing: 0.3rem;
  padding: none;
  margin: none;
}

.nazdaa__projectsPage-content__projectsCard h3 {
  font-family: var(--font-family);
}

.nazdaa__projectsPage-content__projectsCard p {
  font-family: var(--font-family);
}

.nazdaa__projectsPage-content {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}



/*LOADING SPACE*/

.contain {
  animation-delay: 4s;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  background: #25a7d7;
  background: -webkit-linear-gradient(#25a7d7, #2962ff);
  background: linear-gradient(#25a7d7, #25a7d7);
}

.icon {
  width: 100px;
  height: 100px;
  margin: 0 5px;
}

/*Animation*/
.icon:nth-child(2) img {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.icon:nth-child(3) img {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.icon:nth-child(4) img {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.icon img {
  -webkit-animation: anim 2s ease infinite;
  animation: anim 2s ease infinite;
  -webkit-transform: scale(0, 0) rotateZ(180deg);
  transform: scale(0, 0) rotateZ(180deg);
}

@-webkit-keyframes anim {
  0% {
    -webkit-transform: scale(0, 0) rotateZ(-90deg);
    transform: scale(0, 0) rotateZ(-90deg);
    opacity: 0;
  }
  30% {
    -webkit-transform: scale(1, 1) rotateZ(0deg);
    transform: scale(1, 1) rotateZ(0deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1, 1) rotateZ(0deg);
    transform: scale(1, 1) rotateZ(0deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(0, 0) rotateZ(90deg);
    transform: scale(0, 0) rotateZ(90deg);
    opacity: 0;
  }
}

@keyframes anim {
  0% {
    -webkit-transform: scale(0, 0) rotateZ(-90deg);
    transform: scale(0, 0) rotateZ(-90deg);
    opacity: 0;
  }
  30% {
    -webkit-transform: scale(1, 1) rotateZ(0deg);
    transform: scale(1, 1) rotateZ(0deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1, 1) rotateZ(0deg);
    transform: scale(1, 1) rotateZ(0deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(0, 0) rotateZ(90deg);
    transform: scale(0, 0) rotateZ(90deg);
    opacity: 0;
  }
}

@media screen and (max-width: 850px) {
  .nazdaa__projectsPage-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0;
    width: 100%;
    height: 35vh;
  }
  .nazdaa__projectsPage-heading__content{
    margin-left: 4rem;
  }
  .nazdaa__projectsPage-heading h1{
    font-size: 24px;
    margin-bottom: 2rem;
  }
  .nazdaa__projectsPage-heading h3{
    font-size: 12px;
  }
  .nazdaa__projectsPage-content{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .nazdaa__projectsPage-content__h1{
    font-size: 24px;
    
  }
  .nazdaa__projectsPage-content__projectsContainer {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .nazdaa__projectsPage-content__projectsCard{
    width: 15rem;
    height: auto;
  }
  .nazdaa__projectsPage-content__projectsCard img{
    width: 15rem;
    height: auto;
  }
}
