*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
}
.modal-content{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid black;
    padding: 2rem;
    z-index: 9999;
    background: white;
    width: 38vw;
}
.modal-content__groupA {
    width: 17vw;
    border-right: 1px black solid;
    padding-right: 3rem;
}
.modal-header{
    margin-top: 0.4rem;
    margin-bottom: .4rem;
}
.modal-header h2{
    font-family: var(--heading-font);
}
.modal-content__groupA .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgb(43, 43, 43);
    background: none;
    text-decoration: none;
  }
.modal-content__groupA .close-button:hover{
    text-decoration: none;
}
.modal-content__groupA .form-group{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.modal-content__groupA .form-group label{
    font-family: var(--font-family);
    padding-bottom: .5rem;
}
.modal-content__groupA .form-group input{
    margin-top: 5px;
    line-height: 24px;
    font-family: Arial, Helvetica, sans-serif;
}
.modal-content__groupA .submit-button{
    font-family: Arial, Helvetica, sans-serif;
    justify-content: center;
    align-items: center;
}
.modal-content__groupB{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: auto;
}
.modal-content__groupB h3{
    font-family: var(--heading-font);
}
.modal-content__groupB .loginStrategy{
    display: flex;
    flex-direction: row;
    border: 2px solid black;
    border-radius: 5px;
    padding: .5rem;
    justify-content: space-between;
    font-family: var(--heading-font);
}

.modal-content__groupB .loginStrategy h2{
    padding-right: .5rem;
}

.modal-content__groupB .facebook{
    background: #3b5998;
    color: rgb(236, 235, 235);
}
.modal-content__groupB .google{
    background: #F4B400;
}

.modal-content__groupB .loginStrategy:hover{
    cursor: pointer;
}

.modal-content__groupB .icons{
    margin-top: 4px;
}
@media screen and (max-width: 1220px) {
    .modal-content{
        flex-direction: column;
    }
    .modal-content__groupA{
        border: none;
        width: 80%;
        margin: auto;
        padding: 0;
    }
    .modal-content__groupB{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        margin: auto;
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .modal-content__groupB .loginStrategies{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: fit-content;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 600px) {
    .modal-content{
        flex-direction: column;
        gap: 2rem;
        top: 100%;
        left: 10%;
        width: 60vw;
        padding: 1rem;
    }
    
    .modal-content__groupA{
        border: none;
        width: 38vw;
        margin: auto;
        padding: 0;
    }
}
  