@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600&family=Saira+Condensed:wght@300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --color-header: #707F3C;
  --color-footer: rgba(241, 142, 36, 0.06);
  --color-text: #000000;
  --linear-gradient: linear-gradient(180deg, rgba(168, 196, 73, 0) 0%, rgba(168, 196, 73, 0.45) 100%);
  --gradient-bar: radial-gradient(circle, rgba(168,196,73,1) 0%, rgba(241,142,36,1) 100%);
  --font-family: 'Roboto Mono', monospace;
  --header-bg: rgba(241, 142, 36, 0.06);
  --heading-font:'Saira Condensed', sans-serif;;
  --footer-bg: rgba(241, 142, 36, 0.06);
}