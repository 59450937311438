.nazdaa__footer{
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.nazdaa__footer-heading{
    width: 100%;
    text-align: center;

    margin-bottom: 4rem;
}

.nazdaa__footer-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 58px;
    line-height: 75px;

    text-align: center;
    letter-spacing: -0.04em;
}

.nazdaa__footer-btn{
    
    box-sizing: border-box;
    border: 1px solid black;
    padding: 1rem;
    margin-bottom: 4rem;
}

.nazdaa__footer-btn p{
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 21px;

    color: black;
    cursor: pointer;
}

.nazdaa__footer-link{
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    padding-bottom: 2rem;
}

.nazdaa__footer-links_logo{
    flex: 0.25;
    margin-right: 8rem;
    line-height: 3rem;
    font-family: var(--font-family);
    font-size: 12px;
    
    color: black;
}
.nazdaa__footer-links_logo img{
    padding-bottom: 2rem;
    width: 100px;
}
.nazdaa__footer-links_div, .nazdaa__footer-links_div-2, .nazdaa__footer-links_div-3{
    margin-right: 4rem;
    line-height: 3rem;
}

.nazdaa__footer-links_div h4, .nazdaa__footer-links_div-2 h4, .nazdaa__footer-links_div-3 h4{
    font-family: var(--font-family);
    font-size: 14px;
    padding-bottom: 1rem;

    color: black;
}

.nazdaa__footer-links_div p, .nazdaa__footer-links_div-2 p, .nazdaa__footer-links_div-3 p{
    font-family: var(--font-family);
    font-size: 12px;
    cursor: pointer;
    color: black;
}

.nazdaa__footer-rights h6{
    margin-top: 3rem;
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    font-feature-settings: 'liga' off;

    color: black;
}
.nazdaa__footer-links_div-3-1{
    margin-top: 5rem;
}
.nazdaa__footer-links_div-3-1 img{
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
}

@media screen and (max-width: 920px) {
    .nazdaa__footer-links_logo{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0;
    }
    .nazdaa__footer-link{
        flex-direction: column;
        padding: 2rem;
    }

    .nazdaa__footer-heading h1{
        font-size: 45px
    }

    .nazdaa__footer-btn{
        margin-bottom: 2rem;
    }
    .nazdaa__footer-btn p{
        font-size: 12px;
    }
}