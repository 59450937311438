.nazdaa__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.nazdaa__brand img{
    flex: 1;
    margin: 0 8rem 0 8rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.nazdaa__brand .goldcrest{
    max-width: 100px;
    max-height: 100px;
}
.nazdaa__brand .aristocrat{
    max-width: 232px;
    
}
.nazdaa__brand .npc{
    max-width: 150px;
    max-height: 100px;
}

@media screen and (max-width: 1050px) {
    .nazdaa__brand img{
        flex: 1;
        margin: 0 4rem 0 4rem;
    }
    .nazdaa__brand{
        margin-top: 0rem;
    }
}

@media screen and (max-width: 780px) {
    .nazdaa__brand img{
        flex: 1;
        margin: 0 2rem 0 2rem;
    }
    .nazdaa__brand .goldcrest{
        max-width: 80px;
        max-height: 80px;
    }
    .nazdaa__brand .aristocrat{
        max-width: 232px;
        max-height: 150px;
    }
    .nazdaa__brand .npc{
        max-width: 150px;
        max-height: 80px;
    }
}
