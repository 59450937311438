.nazdaa__projects{
    padding-left: 6rem;
}
.nazdaa__projects-heading{
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
}
.nazdaa__projects-heading h1{
    font-family: var(--heading-font);
    color: var(--color-header);
    margin-bottom: 3rem;
}

.nazdaa__projects-heading button{
    background: #9db153;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 80px;
    font-size: 16px;
    border-radius: 5px;
    height: 50px;
    transition: background 0.3s ease;
    font-family: var(--heading-font);
    font-weight: 400;
    font-size: 16px;
}

.nazdaa__projects-heading button:hover{
    background: #707F3C;
    cursor: pointer;
}

.nazdaa_projects-heading__header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
}
.nazdaa_projects-heading__header .header-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nazdaa_projects-heading__header .header-info h1{
    font-family: var(--heading-font);
    color: black;
    
}

.nazdaa__projects-heading__project{
    margin: auto;
    text-align: center;
    padding: .3rem;
}
.nazdaa__projects-heading__project img{
    width: 650px;
    height: 300px;
    margin: auto;
}
.nazdaa__projects-heading__project h3{
    font-family: var(--heading-font);
    font-size: 24px;
    margin-top: .5rem;
}
.nazdaa__projects-heading__project p{
    font-family: var(--font-family);
    font-size: 12px;
    width: 650px;
}

.nazdaa__projects-heading__project .read_button{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 11.649px;
    line-height: 35px;
    color: #FFFFFF;
    cursor: pointer;
}

.nazdaa__projects-container__content img{
    width: 400px;
    height: 200px
}
.nazdaa__projects-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
.nazdaa__projects-container__content{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}


@media screen and (max-width: 920px) {
    .nazdaa__projects{
        padding: 0;
        padding-bottom: 2rem;
    }
    .nazdaa__projects-heading{
        flex-direction: column;
        padding: 0;
    }
    .nazdaa__projects-heading .header-title{
        margin-left: 1rem;
    }
    .nazdaa__projects .nazdaa__projects-heading .header-info h1{
        width: 80%;
    }
    .nazdaa__projects-heading img, .nazdaa__projects-heading h1, .nazdaa__projects-heading p{
        width: 16rem;
    }
    .nazdaa__projects-container .nazdaa__projects-container__content{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .nazdaa__projects-container__content h1, .nazdaa__projects-container__content img, .nazdaa__projects-container__content p{
        width: 16rem;
    }

}