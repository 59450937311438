*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a{
  color: unset;
  text-decoration: none;
}

.lin-gradient__bg{
  background: var(--linear-gradient);
}

.color__headertext{
  color: #707F3C;
}

.section__padding{
  padding: 3.5rem 6rem;
}

@media screen and (max-width: 920px) {
  .section__padding{
    padding: 3rem 0rem;
  }
  

  h1{
    font-size: 22px;
  }

  h2{
    font-size: 18px;
  }

  h3{
    font-size: 14px;
  }
  
  p{
    font-size: 12px;
  }
}

.section__margin{
  margin: 4rem;
}

.color__text{
  color: #000000;
}



.text-focus-in {
	-webkit-animation: text-focus-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: text-focus-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.scale-in-tr {
	-webkit-animation: scale-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
