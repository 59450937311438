@keyframes type {
  0% {
    width: 0px;
  }
  20% {
    width: 100%;
  }
  100% {
    width: 300px;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0px;
  }
  20% {
    width: 100%;
  }
  100% {
    width: 300px;
  }
}

@keyframes type_two {
  0% {
    width: 0px;
  }
  20% {
    width: 0px;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 300px;
  }
}

@-webkit-keyframes type_two {
  0% {
    width: 0px;
  }
  20% {
    width: 0px;
  }
  40% {
    width: 100%;
  }
  100% {
    width: 300px;
  }
}

@keyframes type_three {
  0% {
    width: 0px;
  }
  40% {
    width: 0px;
  }
  60% {
    width: 100%;
  }
  100% {
    width: 300px;
  }
}

@-webkit-keyframes type_three {
  0% {
    width: 0px;
  }
  40% {
    width: 0px;
  }
  60% {
    width: 100%;
  }
  100% {
    width: 300px;
  }
}

@keyframes type_four {
  0% {
    width: 0px;
  }
  60% {
    width: 0px;
  }
  80% {
    width: 300px;
  }
  100% {
    width: 300px;
  }
}

@-webkit-keyframes type_four {
  0% {
    width: 0px;
  }
  60% {
    width: 0px;
  }
  80% {
    width: 300px;
  }
  100% {
    width: 300px;
  }
}

.nazdaa__aboutPage {
  background: #f7f6f6;
}
.nazdaa__aboutPage__groups {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.nazdaa__aboutPage__groupA {
  width: 70vw;
}

.nazdaa__aboutPage__groupA-content {
  margin-top: 4rem;
}
.nazdaa__aboutPage__groupA h1 {
  font-family: var(--heading-font);
  letter-spacing: 0.2rem;
}

.nazdaa__aboutPage__groupA p {
  font-family: var(--font-family);
}

.lead {
  font-size: small;
}

.nazdaa__aboutPage-content {
  margin-top: 4rem;
}

.nazdaa__aboutPage__groupB {
  margin-top: -2rem;
}

.nazdaa__aboutPage-content p {
  font-family: var(--font-family);
}
.nazdaa__aboutPage-vision p {
  font-family: var(--font-family);
}

.nazdaa__aboutPage-content p {
    font-family: var(--font-family);
  }
  .nazdaa__aboutPage-mission p {
    font-family: var(--font-family);
  }

.nazdaa__aboutPage-breaker1 img {
  width: 100%;
}

#computer {
  border-width: 0;
  top: 70px;
  width: 592px;
  position: relative;
  > div {
    position: absolute;
  }

  #front {
    width: 100px;
    top: 54px;
    width: 300px;
    height: 300px;
    -webkit-transform: skew(-10deg, 10deg);
    transform: skew(-10deg, 10deg);
    background-color: #a39a9a;
  }

  #screen {
    top: 70px;
    left: 20px;
    width: 270px;
    height: 200px;
    border-radius: 3px;
    background-color: black;
    -webkit-transform: skew(-10deg, 10deg);
    transform: skew(-10deg, 10deg);
  }

  #text {
    padding: 5px;
    font-size: 12pt;
    top: 80px;
    left: 40px;
    width: 270px;
    color: white;
    height: 65px;
    -webkit-transform: skew(-10deg, 10deg);
    transform: skew(-10deg, 10deg);
    font-family: monospace;
  }

  #text div {
    width: 0px;
    overflow: hidden;
    max-height: 19px;
  }

  #text div:nth-child(1) {
    -webkit-animation: type 10s linear infinite;
    animation: type 10s linear infinite;
    overflow: hidden;
  }

  #text div:nth-child(2) {
    -webkit-animation: type_two 10s linear infinite;
    animation: type_two 10s linear infinite;
    overflow: hidden;
  }

  #text div:nth-child(3) {
    -webkit-animation: type_three 10s linear infinite;
    animation: type_three 10s linear infinite;
    overflow: hidden;
  }

  #text div:nth-child(4) {
    -webkit-animation: type_four 10s linear infinite;
    animation: type_four 10s linear infinite;
    overflow: hidden;
  }

  #side {
    height: 300px;
    width: 90px;
    background-color: #333;
    top: 54px;
    left: 300px;
    -webkit-transform: skew(-10deg, -30deg);
    transform: skew(-10deg, -30deg);
  }

  #top {
    background-color: #797979;
    width: 300px;
    height: 54px;
    left: 72px;
    -webkit-transform: skew(-60deg, 10deg);
    transform: skew(-60deg, 10deg);
  }

  #button {
    -webkit-transform: skew(-10deg, 10deg);
    transform: skew(-10deg, 10deg);
    background-color: #242f45;
    height: 20px;
    width: 40px;
    top: 260px;
  }
}

@media screen and (max-width: 1050px) {
  .nazdaa__aboutPage__groups{
    flex-direction: column-reverse;
    padding: 0;
    margin: auto;
  }
  .nazdaa__aboutPage__groupA{
    width: 100%;
    margin-top: 30rem;
  }
  .nazdaa__aboutPage__groupB {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-left: -3rem;
  }


}

@media screen and (max-width: 750px) {
  .nazdaa__aboutPage{
    padding-bottom: 4rem;
  }
  .nazdaa__aboutPage__groups{
    flex-direction: column-reverse;
    padding: 0;
    margin: auto;
  }
  .nazdaa__aboutPage__groupA{
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0;
  }
  .nazdaa__aboutPage__groupB {
   display: none;
  }

  .nazdaa__aboutPage-content{
    margin-left: 2rem;
    margin-right: 2rem;
    
  }
  .nazdaa__aboutPage-vision{
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .nazdaa__aboutPage-mission{
    margin-left: 2rem;
    margin-right: 2rem;
    
  }


}
