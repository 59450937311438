.nazdaa__header {
    width: 100vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
  }
.nazdaa__header-background{
    position: relative;
    overflow: hidden;
}

.nazdaa__header-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50vw;
    background-image: linear-gradient(0deg, rgba(189, 242, 5, 0.12), rgba(189, 242, 5, 0.12));
 /* Set the opacity value between 0 and 1 */
    opacity: 1;
  }

.nazdaa__header img {
    width: 100vw;
    height: 50vw;
    opacity: 0.6;
  }


.heading-emphasis{
    color: #F18E24;
}

.nazdaa__header-content{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23px;
    
}
.nazdaa__header-content h1{
    font-family: var(--heading-font);
    font-weight: 800;
    font-size: 54px;
    line-height: 55px;
    text-align: center;
    color: #707F3C;
}

.nazdaa__header-content p{
    font-family: var(--heading-font);
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.mask {
    height: 106px;
    position: relative;
    white-space: nowrap; /* Prevent line breaks */
  }
  .mask span {
    display: none;
    box-sizing: border-box;
    position: absolute;
    top: 100px;

    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
  }
  
  .mask span[data-show] {
    display: block;
    transform: translateY(-100%);
    transition: .8s transform ease-in-out;
  }
  
  .mask span[data-up] {
    transform: translateY(-200%);
    opacity: 0.5;
    transition: .8s transform ease-in-out;
  }
  
  .mask span:nth-child(1) {
    background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
  }
  
  .mask span:nth-child(2) {
    background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
  }
  
  .mask span:nth-child(3) {
    background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
  }
  
  .mask span:nth-child(4) {
    background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
  }




@media screen and (max-width: 1050px) {
    .nazdaa__header img {
        height: 60vw;
      }
    .nazdaa__header-background::before{
        height: 60vw;
    }
}
@media screen and (max-width: 680px){
    .nazdaa__header-content{
        margin-top: -3rem;

    }
    .nazdaa__header-content h1{
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
    }
    .nazdaa__header-content p{
        font-size: 12px;
        line-height: 16px;
    }
    .nazdaa__header img {
        height: 80vw;
      }
    .nazdaa__header-background::before{
        height: 80vw;
    }
}