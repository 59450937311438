  .container {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  .container .blockOne {
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    left: 95%;
    height: 300px;
    width: 500px;
    background-color: #00405c;
    border-radius: 1rem;
    z-index: initial;
    animation: block-one-appear 7s alternate ease-in-out infinite;
  }
  @keyframes block-one-appear {
    0% {
      left: 95%;
    }
    10% {
      left: 95%;
    }
    18% {
      left: 75%;
    }
    82% {
      left: 75%;
    }
    90% {
      left: 95%;
    }
    100% {
      left: 95%;
    }
  }
  .container .blockOne .blockTwo {
    position: relative;
    top: 17.5px;
    right: 177.5px;
    height: 265px;
    width: 250px;
    border-radius: 0.25rem;
    z-index: -1;
    background-color: #9c71b4;
    animation: block-two-bounce 7s alternate ease-in-out infinite;
  }
  @keyframes block-two-bounce {
    0% {
      right: 177.5px;
    }
    10% {
      right: 177.5px;
    }
    16% {
      right: 177.5px;
    }
    20% {
      right: 212.5px;
    }
    24% {
      right: 177.5px;
    }
    76% {
      right: 177.5px;
    }
    80% {
      right: 212.5px;
    }
    84% {
      right: 177.5px;
    }
    100% {
      right: 177.5px;
    }
  }
  .container .blockOne .blockTwo .cable {
    position: relative;
    top: 17.5px;
    right: 250px;
    height: 45px;
    width: 250px;
    background-color: #f96c30;
    transform-origin: center right;
    animation: cable-extend 7s alternate ease-in-out infinite;
  }
  .container .blockOne .blockTwo .cable:nth-child(2) {
    background-color: #615ec5;
    animation-delay: 0.15s;
  }
  .container .blockOne .blockTwo .cable:nth-child(2) .data {
    animation-delay: 0.25s;
  }
  .container .blockOne .blockTwo .cable:nth-child(3) {
    background-color: #00afc3;
    animation-delay: 0.075s;
  }
  .container .blockOne .blockTwo .cable:nth-child(3) .data {
    animation-delay: 0.5s;
  }
  .container .blockOne .blockTwo .cable:nth-child(3) .flasher {
    top: -55px;
    right: 470px;
    animation-delay: 0.3s;
  }
  .container .blockOne .blockTwo .cable:nth-child(4) {
    background-color: #e5c21f;
    animation-delay: 0.1s;
  }
  .container .blockOne .blockTwo .cable:nth-child(4) .data {
    animation-delay: 0.3s;
  }
  .container .blockOne .blockTwo .cable:nth-child(4) .flasher {
    top: -25px;
    right: 355px;
    animation-delay: 0.15s;
  }
  .container .blockOne .blockTwo .cable:nth-child(5) {
    background-color: #00c27d;
    animation-delay: 0.05s;
  }
  .container .blockOne .blockTwo .cable:nth-child(5) .data {
    animation-delay: 0.15s;
  }
  .container .blockOne .blockTwo .cable .flasher {
    position: relative;
    top: -17.5px;
    right: 350px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0;
    animation: data-flash 7s ease-in-out infinite;
  }
  @keyframes data-flash {
    0% {
      opacity: 0;
    }
    44% {
      opacity: 0;
    }
    46% {
      opacity: 0.2;
    }
    48% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    52% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  .container .blockOne .blockTwo .cable .data {
    position: relative;
    top: 7.5px;
    right: 200px;
    height: 35px;
    width: 150px;
    background-color: #ffffff;
    opacity: 0;
    animation: data-fire 7s ease-in-out infinite;
  }
  .container .blockOne .blockTwo .cable .data:nth-child(2) {
    top: 25px;
    transform: translateX(-120px);
  }
  .container .blockOne .blockTwo .cable .data:nth-child(3) {
    top: 55px;
    transform: translateX(-270px);
  }
  @keyframes data-fire {
    0% {
      right: 200px;
      opacity: 0;
    }
    40% {
      right: 200px;
      opacity: 0;
    }
    45% {
      opacity: 0.35;
    }
    55% {
      right: 550px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes cable-extend {
    0%,
    24%,
    75%,
    100% {
      width: 0px;
      right: 125px;
    }
    29%,
    70% {
      width: 250px;
      right: 250px;
    }
}