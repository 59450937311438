.nazdaa__about-container__feature-title h1{
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: black;
}

.nazdaa__about-container__feature-title div{
    width: 38px;
    height: 3px;

    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom:0.25rem
}

.nazdaa__about-container__feature-text{
    flex: 2;
    display: flex;
    margin-top: 3rem;
    max-width: 731px;
    letter-spacing: -0.04em;
    margin-right: 6rem;
}

.nazdaa__about-container__feature-text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 1rem auto;
}

@media screen and (max-width: 920px) {
    .nazdaa__about-container__feature-title h1{
        font-family: var(--font-family);
        font-weight: 900;
        font-size: 22px;
        line-height: 22px;
        letter-spacing: -0.04em;
    }
    .nazdaa__about-container__feature-text{
        margin-right: 3rem;
    }
}

@media screen and (max-width: 650px) {
    .nazdaa__about-container__feature-title h1{
        font-family: var(--font-family);
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
    }
    .nazdaa__about-container__feature-text{
        margin-right: 0rem;
    }
}

@media screen and (max-width: 450px) {
    .gpt3__features-container__feature-title h1{
        font-family: var(--font-family);
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
    }
    .gpt3__features-container__feature-text{
        margin-right: 0;
    }
}