.nazdaa__servicesCard{
    background: transparent;
    width: calc(33.33% - 20px);

}

.nazdaa_servicesCard-content{
    margin: auto;
    margin-top: 2rem;
    width: 320px;
    height: 270px;
    transition: background-color 0.3s ease, color 0.5s ease, transform 0.3s ease;
    cursor: pointer;
    border-radius: 5px; /* Initial border radius */
    border: 1px solid transparent; /* Initial border */
}

.nazdaa_servicesCard-content:hover {
    background-color: #707F3C;
    color: #fff;
    transform: scale(1.1);
    border-radius: 10px; 
    border-color: black; /* White border on hover */
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  

.nazdaa_servicesCard-content:hover h1 {
color: #fff;
}

.nazdaa__servicesCard-content__heading h1{
    font-family: var(--heading-font);
    color: var(--color-header);
    line-height: 33px;
    margin-top: 1rem;
    transition: color 0.5s ease;
}
.nazdaa__servicesCard-content__information{
    margin: auto;
    
}

.nazdaa_servicesCard-content img {
    width: 80px;
    height: 80px;
    margin-top: 1rem;
  }

.nazdaa_servicesCard-content__image{
    margin-top: 1rem;
}
.nazdaa_servicesCard-content__text{
    width: 300px;
    margin-left:auto;
    margin-right: auto;
    font-family: var(--font-family);
    font-size: 12px;
}

  
@media screen and (max-width: 768px) {
    .nazdaa__servicesCard{
        padding-left: 0;
        flex-direction: column;
        width: auto;
    }
}

