.nazdaa__projectsCard{
    background-color: #9db153;
    display: flex;
    flex-direction: column;
    padding: .3rem;
    border-radius: 7px;
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.nazdaa__projectsCard:hover{
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.nazdaa__projectsCard-container_article-image{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.nazdaa__projects-container__content{
    justify-content: space-between;
    gap: 3.5rem;
    padding: 1.5rem .3rem;
    width: 100%;
    height: 100%;
    text-align: center;
}
.nazdaa__projectsCard-container_article-content{
    margin-bottom: 4rem;
}
.nazdaa__projects-container__content h3{
    font-family: var(--heading-font);
    font-size: 24px;
    margin-top: 1rem;
    color: rgb(63, 63, 63);
}

.nazdaa__projects-container__content p{
    font-family: var(--font-family);
    font-size: 12px;
}

.nazdaa__projects-container__content .read_button{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 11.649px;
    line-height: 35px;
    color: #FFFFFF;
    cursor: pointer;
}