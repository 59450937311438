.nazdaa__contactsPage-heading {
    height: 24vw;
    background-color: #f4f4f4;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.nazdaa__contactsPage-heading h1{
    font-family: var(--heading-font);
    word-spacing: 1rem;
    letter-spacing: .3rem;
    color: #687b6b;
    font-size: 46px;
}
.nazdaa__contactsPage-heading img{
    width: 34rem;
    margin-top: 10rem;
}

.nazdaa__contactsPage-heading h3{
    font-family: var(--font-family);
    color: #8e8e8e;
    word-spacing: .5rem;
    letter-spacing: .1rem;
    margin-top: .5rem;
}

.nazdaa__contactsPage-body{
  display: flex;
  flex-direction: row;
}

.nazdaa__contactsPage-body__tag{
    display: flex;
    flex-direction: row;
    gap: .4rem;
    width: 40vw;
}

.nazdaa__contactsPage-body__tag h1{
  font-family: var(--font-family);
  font-size: 24px;
}

.nazdaa__contactsPage-body h2{
  font-family: var(--font-family);
  font-size: 20px;
  margin-top: 2rem;
  margin-bottom: 4rem;
  width: 35vw;
}


.nazdaa__contactsPage-body__tag select{
  font-family: var(--heading-font);
  font-size: 24px;
  margin-bottom: .2rem;
  width: 21.5rem;
}


  .form-group {
    margin-bottom: 1rem;
  }
  
  .name-inputs {
    display: flex;
    gap: 1rem;
  }
  
  .name-input {
    flex: 1;
  }

  .nazdaa__contactsPage-body__form {
    width: 65vw;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-gap: 10px;
  }

  .nazdaa__contactsPage-body__form label{
    font-family: var(--font-family);
    font-size: 24px;
    grid-column: 1 / 3;
  }

  .nazdaa__contactsPage-body__form input{
    grid-column: 1 / 3;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    line-height: 2rem;
    margin-bottom: 10px;
    margin-top: .6rem;
    font-size: 20px;
  }

  .fullwidth input, textarea{
    width: 100%;
  }
  
  .fullwidth textarea{
    height: 4rem;
  }
  .nazdaa__contactsPage-body__form .button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  .nazdaa__contactsPage-body__form button{
    border-radius: 5px;
    padding: .4rem;
    font-size: 16px;
    transition: background 0.3s ease, color 0.3s ease;
  }

  .nazdaa__contactsPage-body__form button:hover{
    cursor: pointer;
    background: #707F3C;
    color:#f4f4f4
  }

  .form-group p{
    font-family: var(--font-family);
    margin: 5px;
  }

  .error-message{
    color: red;
  }

  .swal2-popup {
    font-family: var(--heading-font);
  }

  .swal2-content {
    font-family: var(--font-family);
  }
  
  .cctv-animation {
    transform: rotate(35deg) scaleX(-1);
    margin-left: -2rem;
    overflow: hidden;
  }
  
  
  .nazdaa__contactsPage .cable-animation{
    height: 40vh;
    background: var(--color-header);
    overflow: hidden;
  }


/* Loading animation */

.lds-ellipsis {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #10c300;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.253);
  z-index: 9998;
}
@media screen and (max-width: 1220px) {
  .nazdaa__contactsPage-heading h1{
    font-size: 36px;
  }
  .nazdaa__contactsPage-heading img{
    width: 24rem;
    margin-top: 3rem;
  }
  .nazdaa__contactsPage-body h1{
    font-size: 24px;
  }
}

@media screen and (max-width: 920px) {
  .cctv-animation{
    display: none;
  }
  .cable-animation{
    display: none;
  }
  .nazdaa__contactsPage-body{
    margin: auto;
  }
  .nazdaa__contactsPage-heading{
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .nazdaa__contactsPage-heading__content{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nazdaa__contactsPage-heading h1{
    font-size: 24px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .nazdaa__contactsPage-heading img{
    display: none;
  }
  .nazdaa__contactsPage-body__form h1{
    font-size: 24px;
  }
  
  .nazdaa__contactsPage-body{
    flex-direction: column;
    width: 100%;
  }
  .nazdaa__contactsPage-body__form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }
  .nazdaa__contactsPage-body__form form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }
  .nazdaa__contactsPage-body__form .name-inputs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
  }
  .nazdaa__contactsPage-body__form .nazdaa__contactsPage-body__tag{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }


  .nazdaa__contactsPage-body__form{
    flex-direction: column;
  }
  .nazdaa__contactsPage-body__form label{
    font-size: 16px;
    margin-bottom: .2rem;
  }
  .nazdaa__contactsPage-body__form input{
    width: 100%;
    margin-top: .5rem;
  }
  .fullwidth{
    width: 80%;
  }
  .nazdaa__contactsPage-body__form textarea{
    width: 80vw;
  }
  .nazdaa__contactsPage-body__form h2{
    width: 80%;
    font-size: 13px;
  }
  .nazdaa__contactsPage-body__tag{
    padding: 0;
  }
  .nazdaa__contactsPage-body__tag h1{
    font-size: 16px;
  }
  .nazdaa__contactsPage-body__tag select{
    font-size: 16px;
    width: 50%;
  }
}