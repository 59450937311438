.nazdaa__navbar-bg{
    background: var(--header-bg);
}

.nazdaa__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem 1rem 6rem;
}

.nazdaa__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    
    align-items: center;
}


.nazdaa__navbar-links_logo{
    margin-right: 2rem;
}

.nazdaa__navbar-links_logo img{
    width: 120px;
    height: 42px;
}

.nazdaa__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.nazdaa__navbar-sign{
    display:flex;
    justify-content: flex-end;
    align-items:center;
}

.nazdaa__navbar-links_container p, .nazdaa__navbar-sign p, .nazdaa__navbar-sign button, .nazdaa__navbar-menu_container p{
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    margin-right: 1.5rem;
    cursor: pointer;
    opacity: 0.4;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 400;
    transition: opacity 0.5s ease, text-decoration 0.5s ease;
}

.nazdaa__navbar-links_container p:hover{
    opacity: 1.5;
    text-decoration: underline #707F3C;
}

.nazdaa__navbar-sign p:hover{
    opacity: 1;
    text-decoration: underline #707F3C;
}

.nazdaa__navbar-sign button:hover{
    opacity: 1;
    text-decoration: underline #707F3C;
}


.nazdaa__navbar-menu_container-links-sign p:hover{
    opacity: 1.5;
    text-decoration: underline #707F3C;
}

.nazdaa__navbar-sign button,
.nazdaa__navbar-menu_container button{
    padding: 0.3rem 0.3rem;
    color: white;
    background: #707F3C;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    border-radius: 5px;
    font-family: var(--font-family);
    border: 0;
    outline: none;
    cursor: pointer;
    margin-left: 0.5rem;
    text-transform: uppercase;
}

.nazdaa__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}

.nazdaa__navbar-menu svg{
    cursor:pointer;
}

.nazdaa__navbar-menu_container .text-focus-in{
    z-index: 9999;
}

.nazdaa__navbar-menu_container {
    display:flex;
    justify-content:flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;

    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;

    margin-top:1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);  
}

.nazdaa__navbar-menu_container p{
    margin: 1rem 0;
}
.nazdaa__navbar-menu_container p:hover{
    opacity: 1;
    text-decoration: underline #707F3C;
}
.nazdaa__navbar-menu_container-links-sign{
    display: none;
}
.user-info{
    display: flex;
    justify-content: space-between;
    gap: 4rem;
}
.user-info .display-info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.user-info .display-info img{
    width: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.user-info .logout__pc{

    display: flex;
    justify-content: center;
    align-items: center;
}



@media screen and (max-width: 1300px) {
    .nazdaa__navbar {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .nazdaa__navbar-menu_container {
        top: 5px;
    }
    .nazdaa__navbar-links_container {
        display: none;
    }

    .nazdaa__navbar-menu {
        display: flex;
        z-index: 9999;
    }

    .nazdaa__navbar-menu_container-links{
        background: #FEF8F2;
        padding: 0 2rem 1.8rem;
    }
    .nazdaa__navbar-links_container p, .nazdaa__navbar-sign, .nazdaa__navbar-menu_container p, .nazdaa__navbar-sign button{
        font-size: 12px;
        margin-right: 0.5rem;
        
    }
    .logout__mobile{
        display: contents;
    }
    .nazdaa__navbar-sign .user-info .logout__pc p{
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .nazdaa__navbar {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .nazdaa__navbar-links_container p, .nazdaa__navbar-sign, .nazdaa__navbar-menu_container p, .nazdaa__navbar-sign button{
        font-size: 12px;
    }
    .nazdaa__navbar-menu_container {
        top: 5px;
    }
}

@media screen and (max-width: 600px) {
    .nazdaa__navbar {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .nazdaa__navbar-sign{
        display: none;
    }   

    .nazdaa__navbar-menu_container {
        top: 5px;
    }

    .nazdaa__navbar-menu_container-links-sign{
        display: block;
    }

    .nazdaa__navbar-menu_container-links-sign button{
        font-size: 12px;
    }

    .nazdaa__navbar-menu_container-links-sign .display-info{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .nazdaa__navbar-menu_container-links-sign .display-info img{
        border-radius: 50%;
        width: 2rem;
    }
    .logout__mobile{
        display: contents;
    }
}
