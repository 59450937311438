.nazdaa__services{
    padding-left: 6rem;
    background: var(--linear-gradient);
    width: 100%;
}
.nazdaa__services-buttonContainer{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nazdaa_services-heading h1{
    font-family: var(--heading-font);
    color: var(--color-header);
    margin-bottom: 3rem;
}
.nazdaa__services-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.nazdaa__services-cta{
    background: #9db153;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 85px;
    transition: background 0.3s ease;
    font-family: var(--heading-font);
    font-weight: 400;
    font-size: 16px;
    border-radius: 5px;
    height: 50px;
}

.nazdaa__services-cta:hover{
    background: #707F3C;
    cursor: pointer;
}


@media screen and (max-width: 920px) {
    .nazdaa__services{
        width: 100%;
    }
    .nazdaa__services-container{
        flex-direction: column;

    }
    
    .nazdaa__services h1{
        font-size: 24px;
    }

    .nazdaa_services-heading{
        margin: 1rem;
    }
}
